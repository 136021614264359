<template>
  <div class="inspection-map-container">
    <!-- Loading Spinner -->
    <loader v-if="isLoading"></loader>
    <!-- Error Message -->
    <div v-else-if="error" class="error-container">
      <p>{{ error }}</p>
      <button @click="fetchInspectionLocations">Retry</button>
    </div>
    <!-- Map Display -->
    <template v-else-if="recentInspectionLocations.length > 0">
      <gmap-map
          :center="mapCenter"
          :zoom="10"
          style="width: 100%; height: 500px"
      >
        <gmap-marker
            v-for="(location, index) in recentInspectionLocations"
            :key="index"
            :position="{ lat: location.location.latitude, lng: location.location.longitude }"
            :clickable="true"
            @click="showLocationDetails(location)"
        ></gmap-marker>
      </gmap-map>
    </template>
    <!-- No Locations Message -->
    <div v-else class="no-locations-container">
      <p>No recent inspection locations found.</p>
      <button @click="fetchInspectionLocations">Refresh</button>
    </div>
    <!-- Location Details Modal -->
    <div v-if="selectedLocation" class="location-details-modal">
      <div class="modal-content">
        <p><strong>Asset: </strong>
          <router-link
              class="has-text-weight-bold has-text-link"
              :to="{
                    name: 'asset-overview',
                    params: {
                      asset: selectedLocation.asset.id,
                    }
                  }"
          >{{ selectedLocation.asset.code }}
          </router-link>
        </p>
        <p><strong>Started: </strong> {{ selectedLocation.created_at | date('L LTS')}}</p>
        <p v-if="selectedLocation.completed_at"><strong>Completed: </strong> {{ selectedLocation.completed_at | date('L LTS') }}</p>
        <p><strong>Is Defective: </strong> {{ selectedLocation.asset.isDefective }}</p>
        <button @click="selectedLocation = null">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import {common as commonBackend} from "@/api";

export default {
  name: 'InspectionLocationsMap',
  data() {
    return {
      mapCenter: null,
      selectedLocation: null,
      recentInspectionLocations: [],
      isLoading: true,
      error: null
    }
  },
  mounted() {
    // Fetch locations when component is mounted
    this.fetchInspectionLocations();
  },
  methods: {
    fetchInspectionLocations() {
      this.isLoading = true;
      this.error = null;
      this.recentInspectionLocations = [];
      commonBackend.loadPath({path: `api${this.$route.fullPath}inspections`}, ({data}) => {
        if (data) {
          if (data?.data) {
            this.recentInspectionLocations = data.data.filter(i => i.location);
            const [first] = this.recentInspectionLocations;
            this.mapCenter = first ?
                {lat: first.location.latitude, lng: first.location.longitude} :
                {lat: 0, lng: 0};
            this.isLoading = false;
          }
        }
      }, error => {
        this.error = error.message || 'Failed to load inspection locations';
        this.isLoading = false;
      })
    },

    showLocationDetails(location) {
      this.selectedLocation = location;
    }
  }
}
</script>

<style scoped>
.inspection-map-container {
  margin: 0 auto;
  text-align: center;
}

.error-container, .no-locations-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.location-details-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
}

button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #2980b9;
}
</style>